@import url(https://fonts.googleapis.com/css2?family=Lora:wght@700&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  font-family: 'Source Sans Pro', sans-serif;
}

h1, h2, h3, h4, h5 {
  font-family: 'Lora', serif;
}

.text-burgundy{
  color:#800020
}

.text-lora{
  font-family: 'Lora', serif;
}

.opacity-0{
  opacity:0;
  font-family: 'Lora', serif;
}

.opacity-1{
  opacity:1;
  transition:1s;
  font-family: 'Lora', serif;
}

.custom-card-img{
    object-fit: 'cover';
    max-height: '10rem';
    object-position: '50% 20%';
    cursor:'pointer';
}

img.custom-card-img:hover{
  transform: scale(1.02);
  transition:0.5s;
}
